@import "../../styles/Variables.scss";

.footer {
  background-color: $main-primary;
  padding-top: 80px;
  @media screen and (max-width: 767px) {
    padding-top: 50px;
  }
  .logo {
    img {
      width: 120px;
      object-fit: contain;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }
  }
  .footer-links {
    color: $white;
    h5 {
      margin-bottom: 20px;
      font-family: "Product Sans Bold";
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }
    .footer-list {
      @extend %list-spacing;
      li {
        margin-bottom: 10px;
        a {
          color: $white;
        }
      }
    }
    .social-list-footer {
      display: flex;
      align-items: center;
      @extend %list-spacing;
      li {
        margin-right: 15px;
        a img {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .footer-sub {
    padding: 20px 0;
    p {
      text-align: right;
      font-size: 12px;
      color: $white;
      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
  }
}
