@import "../../styles/Variables.scss";

.header {
  padding: 5px 0;
  z-index: 1;
  position: relative;
  backdrop-filter: blur(30px);
  .navbar {
    .navbar-brand {
      img {
        width: 95px;
        object-fit: contain;
        object-position: center;
        padding: 10px;
        background-color: $white;
      }
    }
    .navbar-toggler {
      background-color: $white;
      &:focus {
        box-shadow: none;
      }
    }
    .navbar-nav {
      align-items: center;
      @media screen and (max-width: 767px) {
        padding-top: 10px;
        background-color: $white;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        padding-top: 10px;
        background-color: $white;
      }
      li {
        margin: 0 25px;
        @media (min-width: 992px) and (max-width: 1200px) {
          margin: 0 10px;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          color: $main-primary;
          border-bottom: 2px solid transparent;
          font-size: 14px;
          &:hover {
            border-bottom: 2px solid $main-primary;
          }
          &.active {
            border-bottom: 2px solid $main-primary;
          }
        }
        &:last-child {
          a {
            &:hover {
              border-bottom: none;
            }
            &.active {
              border-bottom: none;
            }
          }
        }
        @media screen and (max-width: 767px) {
          margin: 0 0 10px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          margin: 0 0 10px;
        }
      }
      &.navbar-white {
        li {
          a {
            color: $white;
            border-bottom: 2px solid transparent;
            @media screen and (max-width: 767px) {
              color: $black;
            }
            @media (min-width: 768px) and (max-width: 991px) {
              color: $black;
            }
            &:hover {
              border-bottom: 2px solid $white;
            }
            &.active {
              border-bottom: 2px solid $white;
            }
          }
          &:last-child {
            a {
              &:hover {
                border-bottom: none;
              }
              &.active {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}
