@import "../../styles/Variables.scss";

.subscription-page {
  min-height: calc(100vh - 410px);
  margin: 40px 0;
  .plan-card {
    min-height: 334px;
    box-shadow: 2px 3px 12px #ddd;
    border-radius: 20px;
    overflow: auto;
  }

  .plan-choose-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey;
    border-radius: 10px;
    padding: 5px;
    width: 265px;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.subscription-card {
  border: 1px solid rgba($black, 0.08);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  .card-top {
    margin-bottom: 20px;
    h2 {
      color: rgba($primary, 1);
      font-size: 24px;
      line-height: 30px;
      margin: 0px;
    }
  }
  .card-mid {
    p {
      color: rgba($black, 1);
      font-size: 16px;
      line-height: 20px;
      margin: 0px 0px 15px;
    }
    .action-btn {
      display: flex;
      align-items: center;
      .main-button {
        margin-right: 10px;
      }
    }
  }
}
