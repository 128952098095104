@import "../../styles/Variables.scss";

.userauth-form {
  padding: 20px 50px;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    padding: 20px;
    height: 100%;
    margin-top: 20px;
  }
  .logo {
    margin-bottom: 20px;
    width: 120px;
    object-fit: contain;
  }
  form {
    h4 {
      color: $secondary;
    }
    .social-auth {
      display: flex;
      align-items: center;
      margin-top: 20px;
      justify-content: space-around;
      @media (min-width: 768px) and (max-width: 991px) {
        flex-flow: column;
        span {
          margin-top: 10px;
        }
      }
      @media screen and (max-width: 767px) {
        flex-flow: column;
        span {
          margin-top: 10px;
        }
      }
      // .google-btn {
      //   border-radius: 10px;
      //   border: 1px solid $light-grey;
      //   background-color: $white;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   position: relative;
      //   color: $grey;
      //   margin-bottom: 10px;
      //   overflow: hidden;
      //   img {
      //     width: 25px;
      //     height: 25px;
      //     position: absolute;
      //     top: 8px;
      //     left: 30px;
      //   }
      // }
      .my-facebook-button-class {
        width: 100%;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $white;
        border: 1px solid $light-grey;
        font-size: 14px;
        i {
          color: $primary;
          margin-right: 10px;
        }
      }
    }
    .or {
      position: relative;
      text-align: center;
      margin: 25px 0;
      &::after {
        position: absolute;
        content: "";
        top: 12px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $light-grey;
      }
      span {
        padding: 0 10px;
        background-color: #fff;
        position: relative;
        z-index: 2;
      }
    }
  }
}

.banner-auth {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  @media (min-width: 768px) and (max-width: 991px) {
    object-position: left;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.otp-input {
  height: 60px;
  margin: 0 5px;
  input {
    text-align: center !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100%;
    border: none;
    &:hover {
      box-shadow: none !important;
    }
  }
}

.custom-select-join {
  .form-check {
    padding: 0;
    label {
      margin: 0;
      padding: 0 10px !important;
    }
  }
  .auth-msg {
    padding: 0 !important;
  }
}
.cursor-pointer {
  cursor: pointer;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.7;
  display: none;
}
