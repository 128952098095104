// color code theme -----------
$primary: #096DD9;
$main-primary: #0050B3;
$fade-primary: #E6F7FF;
$secondary: #030B61;

$grey: #707070;
$tab-grey: #F5F5F5;
$light-grey: #ced4da;

$black: #000000;
$white: #ffffff;

$success-msg: #06aa00;
$warning-msg: #efa858;
$error-msg: #d90000;

//list spacing ---------
%list-spacing {
  list-style: none;
  padding: 0;
  margin: 0;
}
