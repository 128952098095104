@import "./Variables.scss";

//font facing style ------------

@font-face {
  font-family: "Product Sans Regular";
  font-display: swap;
  src: url(../assets/fonts/product-sans-regular.woff) format("woff");
}

@font-face {
  font-family: "Product Sans Bold";
  font-display: swap;
  src: url(../assets/fonts/product-sans-bold.woff) format("woff");
}

html {
  height: 100%;
}

body {
  font-family: "Product Sans Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

//root height set -------------

#root {
  height: 100%;
}

//heading tag style ----------

h1 {
  font-size: 35px;
}

h2 {
  font-size: 26px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}
.font14 {
  font-size: 14px;
}

//common button style ------------
.main-button {
  padding: 8px 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  &.primary-btn {
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;
  }
  &.mainprimary-btn {
    background-color: $main-primary;
    color: $white;
    border: 1px solid $main-primary;
  }
  &.white-btn {
    background-color: $white;
    color: $primary;
    border: 1px solid $white;
  }
  &.button-radius {
    border-radius: 100px;
  }
  &.button-lg {
    padding: 8px 40px;
  }
  svg {
    width: 30px;
    height: 20px;
  }
  &.btn-sm {
    padding: 8px 30px;
  }
}

.colorPrimary {
  color: $primary;
}

.icon-btn {
  padding: 0;
  background: transparent;
  border-radius: 100px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: absolute;
  top: 5px;
  right: 10px;
  transition: 0.4s all;
  &:hover {
    box-shadow: 1px 2px 8px #00000024;
  }
  img {
    width: 20px;
    object-fit: contain;
  }
}

//common input style -----------
.form-group {
  margin-bottom: 10px;
  label {
    color: $secondary;
    margin-bottom: 10px;
  }
  .align-icon {
    position: relative;
    input {
      padding-right: 40px;
      padding-left: 15px;
    }
    .end-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 15px;
      right: 16px;
    }
  }
  select {
    padding: 10px 20px !important;
    height: 46px;
  }
  input,
  select {
    padding: 8px 20px;
    border-radius: 10px;
    -moz-appearance: auto; /* Firefox */
    -webkit-appearance: auto; /* Safari and Chrome */
    appearance: auto;
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $light-grey;
    }
    &:hover {
      box-shadow: 0px 0px 20px #0050b333;
    }
  }
  textarea {
    resize: none;
  }
  .auth-msg {
    font-size: 12px;
    padding: 5px 0 0 10px;
    p {
      margin: 0;
    }
    &.error {
      color: $error-msg;
    }
  }
}

.icon-input {
  position: relative;
}

//common list style ------------
.list-relative {
  position: relative;
  .common-list {
    position: absolute;
    top: 76px;
    left: 0;
    background-color: $white;
    width: 100%;
    max-height: 200px;
    overflow: auto;
    z-index: 3;
    ul {
      @extend %list-spacing;
      border: 1px solid $light-grey;
      li {
        border-bottom: 1px solid $light-grey;
        padding: 8px 20px;
        cursor: pointer;
        &:hover {
          background-color: $tab-grey;
        }
        &:last-child {
          border: none;
        }
      }
      p {
        margin: 0;
        padding: 10px 0;
        text-align: right;
        span {
          color: $secondary;
          text-decoration: underline;
        }
      }
    }
  }
}

.list-style-landlord {
  @extend %list-spacing;
  p {
    margin: 0;
    span {
      color: $primary;
      text-decoration: underline;
      margin-left: 5px;
    }
  }
}

//about style -----------
.about-main {
  margin: 50px 0;
  .about-banner-img {
    img {
      width: 100%;
    }
  }
}

.privacy-policy {
  margin: 50px 0;
}

.modal-show {
  display: block;
  background-color: rgba(0, 0, 0, 0.3);
  // &::after {
  //   position: absolute;
  //   content: "";
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.3);
  // }
  .modal-dialog {
    z-index: 1;
  }
}
.form-height-set {
  min-height: calc(100vh - 400px);
}

.img-popup-review {
  max-height: 300px;
  overflow: auto;
  position: relative;
  .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    transform: translate(-50%, -50%);
    button {
      font-size: 35px !important;
      color: #000 !important;
    }
  }
  img {
    object-fit: contain !important;
    width: 100% !important;
    height: 250px !important;
  }
}

.rating-list p + div {
  width: 100%;
}
.pagenotfound {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-30%, -40%);
}
.pagenotfound h2 {
  position: absolute;
  bottom: 168px;
  left: 235px;
  right: 0;
  color: #4977f9;
}
.pagenotfound img {
  position: relative;
}

.custom-space-remove {
  padding: 0;
  .form-check {
    padding: 0;
  }
  label {
    margin-bottom: 0 !important;
  }
}

//checkout-form-page
.checkout-form-page {
  min-height: calc(100vh - 410px);
}

//checkout-card
.checkout-card {
  border: 1px solid rgba($black, 0.08);
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;
  .card-top {
    margin-bottom: 20px;
    h2 {
      color: rgba($primary, 1);
      font-size: 24px;
      line-height: 30px;
      margin: 0px;
    }
  }
  .card-mid {
    p {
      color: rgba($black, 1);
      font-size: 16px;
      line-height: 20px;
      margin: 0px 0px 15px;
    }
    .action-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//dfsd
.common-modal {
  .modal-header {
    display: flex;
    align-items: center;
    padding: 15px 10px 15px;
    border-bottom: 1px solid rgba($black, 0.3);
    .heading {
      width: calc(100% - 40px);
      h2 {
        color: rgba($black, 1);
        font-size: 20px;
        line-height: 24px;
        margin: 0px;
      }
    }
    .modal-closer {
      text-align: right;
      .closer-btn {
        padding: 0px;
        margin: 0px;
        outline: none;
        box-shadow: none;
        border: 0px;
        background: transparent;
        svg {
          width: 18px;
          height: 18px;
          min-width: 18px;
        }
      }
    }
  }
}

//bg colors
.bg-primary {
  background-color: rgba($primary, 1) !important;
}
.upload-outer {
  display: flex;
  flex-wrap: wrap;
}
.upload-img {
  width: 250px;
  height: 200px;
}

.image-list-custom-modal {
  margin-bottom: 30px;
  margin-right: 30px;
  position: relative;
  width: 100%;
  video {
    width: 100% !important;
    height: 153px;
    min-height: 153px;
  }
  img {
    width: 100% !important;
    height: 145px;
    min-height: 145px;
  }
  button {
    position: absolute;
    right: 0px;
    top: 5px;
    background: transparent;
    display: inline-flex;
    border: none;
    svg {
      height: 20px;
      width: 20px;
      object-fit: contain;
      fill: #000;
      background: #fff;
      border-radius: 100%;
      padding: 1px;
    }
  }

  .card-body {
    padding: 5px 15px;
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }
}
.card-user-info {
  display: flex;
  justify-content: space-between;
  p {
    width: 60%;
    &:first-child {
      width: 40%;
    }
  }
}
.error {
  p {
    color: #d90000 !important;
    font-size: 12px;
  }
}

.subscription-icon {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 20px 0;
}

.uppercase {
  text-transform: uppercase;
}

.main-video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.advertisement-info-modal{
  img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
    max-height: 350px;
  }

  video{
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
  }

  .advertisement-info{
    h2{
      font-size: 20px;
      line-height: normal;
      margin: 0px 0px 10px;
      color: $black;
      font-weight: 700;
    }
    p{
      font-size: 16px;
      line-height: 22px;
      margin: 0px 0px 15px;
      color: $black;
      font-weight: 400;
    }
  }
}

.payment-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 387px);

  & > div {
    width: 40%  ;
  }
}

.payment-method-choose {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  & > div {
    gap: 10px;
    box-shadow: 4px 1px 10px #ddd;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 150px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    img {
      width: 80px;
      height: 50px;
      object-fit: contain;
    }
  }
}